/**
 * @author Lee Ji Hoon <jihoonlee@dodlab.kr>
 * @module Saga/NotificationSaga

 */
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { requestFetch, REQUEST_TYPE } from '../../utils/requestFetch';
import BASIC_CONSTANT from '../../constants/basic.constants.js/basic.constants.js';
import SystemError from '../../utils/exception/SystemError';
import { ON_NOTIFICATION } from '../NotificationRedux';

export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';

export const getUserRedux = state => state.UserRedux;

/**
 * notification Saga
 * @param {object} notification
 */
function* sendNotification({ notification }) {
  try {
    const userRedux = yield select(getUserRedux);
    const user = userRedux.user;

    // const token = user.token;

    //여기서 백엔드에 데이터 보냄
    // yield console.log('데이터 보냄', notification);
    //보내고 난 뒤

    yield put({ type: RESET_NOTIFICATION, notification: null });

    //reqeust 샘플 소스
    // const result = yield call(requestFetch, `${BASIC_CONSTANT.BASE_URL}user/me`, REQUEST_TYPE.GET, {}, { token });
    // if (result.code === 200) {
    //   localStorage.setItem(BASIC_CONSTANT.USER_TOKEN_KEY, token);
    //   yield put({ type: UPDATE_USER, user: result.data });
    //   Router.push('/');
    // }
  } catch (e) {
    //예외처리
    SystemError.processError(e);
  }
}

function* NotificationSaga() {
  yield takeLatest(SEND_NOTIFICATION, sendNotification);
}

export default NotificationSaga;
