const URL_CONSTANT = {
  home: {
    url: '/',
    loginCheck: false,
    mainTitle: 'YouTube 플레이리스트 공유 및 원격 재생',
    description: 'BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  login: {
    url: '/login',
    loginCheck: false,
    mainTitle: '로그인',
    description: 'BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  download: {
    url: '/download',
    loginCheck: false,
    mainTitle: '다운로드',
    description:
      'BGMs (브금쓰)는 Mac & Windows에서 무료로 사용할 수 있습니다. YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  'policy/privacy': {
    url: '/policy/privacy',
    loginCheck: false,
    mainTitle: '개인정보처리방침',
    description: 'BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  'policy/service': {
    url: 'policy/service',
    loginCheck: false,
    mainTitle: '이용약관',
    description: 'BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  myChannel: {
    url: '/myChannel',
    loginCheck: true,
    mainTitle: '마이 채널',
    title: 'My channel',
    description: 'BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  channelIntro: {
    url: '/channelIntro',
    loginCheck: true,
    channelDetail: true,
  },
  player: {
    url: '/player',
    loginCheck: true,
    channelDetail: true,
    mainTitle: '‘채널명(변수)’의 플레이어',
    description: 'BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  'player/local': {
    url: '/player/local',
    loginCheck: true,
    channelDetail: true,
    mainTitle: '‘채널명(변수)’의 Local 플레이어',
    description: 'BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  myAccount: {
    url: '/myAccount',
    loginCheck: true,
    mainTitle: '마이 어카운트',
    title: 'My account',
    description: 'BGMs (브금쓰)로 YouTube 플레이리스트를 공유하고, 공간에 음악을 채워보세요.',
  },
  'auth/success': {
    url: '/auth/success',
    loginCheck: false,
    mainTitle: 'Error',
  },
  error: {
    url: '/error',
    loginCheck: false,
    mainTitle: 'Error',
  },
  'error/forbidden': {
    url: '/error/forbidden',
    loginCheck: false,
    mainTitle: 'Error',
  },
  'error/serverCheck': {
    url: '/error/serverCheck',
    loginCheck: false,
    mainTitle: 'Error',
  },
  'error/unauth': {
    url: '/auth/unauth',
    loginCheck: false,
    channelDetail: false,
    mainTitle: 'Error',
  },
  'error/notFound': {
    url: '/auth/notFound',
    loginCheck: false,
    mainTitle: 'Error',
  },
  testpage: {
    url: '/testpage',
    loginCheck: false,
    mainTitle: 'Error',
  },
};

export default URL_CONSTANT;
