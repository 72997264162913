import React, { useEffect, useState } from 'react';
import 'assets/css/style.scss';
import { appWithTranslation } from 'utils/with-i18next';
import { createWrapper } from 'next-redux-wrapper';

import { createStore, compose, applyMiddleware } from 'redux';
import reducer from '../reducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import RootSaga from '../redux/saga/RootSaga';
import handler from '../utils/sentry';

const RootApp = ({ Component, pageProps }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      handler();
    }
  }, []);

  return (
    <React.Fragment>
      <Component {...pageProps}></Component>
    </React.Fragment>
  );
};

const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware]; // 미들웨어들을 넣으면 된다.
  const enhancer =
    process.env.NODE_ENV === 'production'
      ? compose(applyMiddleware(...middlewares))
      : composeWithDevTools(applyMiddleware(...middlewares));
  const store = createStore(reducer, initialState, enhancer);
  sagaMiddleware.run(RootSaga);
  return store;
};

const wrapper = createWrapper(configureStore, { debug: false });

export default appWithTranslation(wrapper.withRedux(RootApp));
