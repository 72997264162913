export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_CHANNEL_USER = 'UPDATE_CHANNEL_USER';

export const initialState = {
  // user: 'checking',
  user: null,
  channelUser: null,
};

const UserRedux = (state = initialState, action) => {
  // 리듀서
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
      };
    case UPDATE_CHANNEL_USER:
      return {
        ...state,
        channelUser: action.channelUser,
      };

    default:
      return state;
  }
};

export default UserRedux;
