import { REQUEST_RESULT, REQUEST_CODE } from './request.constants';
import Router from 'next/router';

const USER_TOKEN_KEY = 'user_token_key';

var localStorage = require('localStorage');
let queryString = require('query-string');

export default class SystemError {}

SystemError.processError = (e, component) => {
  console.warn(e);
  if (e.code) {
    if (e.code === 401) {
      localStorage.setItem(USER_TOKEN_KEY, 'null');
      window.location.href = '/';
    } else if (e.code === 403) {
      //예외처리
    } else if (e.code === 404) {
      //예외처리
    }
  } else {
    // window.location.href = '/errorPage';
  }
};

SystemError.processRequestError = (e, component = null) => {
  if (e.code === 401) {
    return REQUEST_RESULT(true, e.code, e);
  } else if (e.code === 9998) {
    // window.location.href = '/errorPage';
  } else {
    let query = queryString.stringify(e);
    // ErrorJson에 명시된 error 일 경우 Description 데이터를 반환함.
    if (e.code) return REQUEST_RESULT(true, e.code, e.description ? e.description : e.data);
    return REQUEST_RESULT(true, REQUEST_CODE.FEE, e);
  }
};
