var queryString = require('query-string');
import SystemError from './exception/SystemError';
import ErrorJson from './exception/ErrorJson.json';
import { v4 as uuidv4 } from 'uuid';
import ErrorHandle from 'components/Common/ErrorHandle';

const getOS = () => {
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  let os = 'Windows';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'mac';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'win';
  }

  return os;
};

export const REQUEST_TYPE = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'PATCH',
};

export const generateResult = (code, message, data) => {
  let description = '';

  if (code > 200) {
    //에러 처리
    if (data.errorCode) {
      description = ErrorJson[data.errorCode];
    }
  }

  if (data.errorCode !== 100000) {
    let error = {
      code,
      message: data.errorMessage,
      data: data.data,
      statusCode: data.errorCode,
      description,
    };
    ErrorHandle(error);
  }

  return {
    code,
    message: data.errorMessage,
    data: data.data,
    statusCode: data.errorCode,
    description,
  };
};
export const requestFetch = async (url, method, data = {}, options = {}, isMultipart) => {
  try {
    let Authorization = {};
    if ('token' in options && options.token && options.token !== 'null') {
      Authorization = { Authorization: `Bearer ${options.token}` };
      delete options['token'];
    }

    let version = localStorage.getItem('version');

    const os = getOS();

    if (version) version = JSON.parse(version);

    let body = method !== REQUEST_TYPE.GET ? { body: JSON.stringify(data) } : {};
    if (isMultipart) {
      let formData = new FormData();
      for (const name in data) {
        formData.append(name, data[name]);
      }
      body = formData;
    }

    if (method === REQUEST_TYPE.GET) {
      if (data && Object.keys(data).length > 0)
        url +=
          '?' + queryString.stringify(data) + '&requestId=' + uuidv4() + '&version=' + version[os] + '&caller=' + 'web';
      else url += '?requestId=' + uuidv4() + '&version=' + version[os] + '&caller=' + 'web';
    } else {
      url += '?requestId=' + uuidv4() + '&version=' + version[os] + '&caller=' + 'web';
      if (method === REQUEST_TYPE.DELETE) {
        for (const [key, value] of Object.entries(data)) {
          url += '&' + key + '=' + value;
        }
      }
    }

    let headers = {
      ...Authorization,
    };
    if (!isMultipart) headers['Content-Type'] = 'application/json';

    let response;
    if (method === REQUEST_TYPE.GET) {
      response = await fetch(url, {
        method,
        headers,
      });
    } else if (isMultipart) {
      response = await fetch(url, {
        method,
        body,
        headers,
      });
    } else {
      response = await fetch(url, {
        method,
        ...body,
        headers,
      });
    }
    if (response.status === 200) {
      //response 성공
      return generateResult(
        response.status,
        response.statusText,
        method !== REQUEST_TYPE.DELETE ? await response.json() : {}
      );
    } else {
      throw response;
    }
  } catch (error) {
    // console.log(error.message);
    if (error.status) {
      //reqeust 에러
      let data = '';
      try {
        data = await error.json();
      } catch (e) {
        data = {};
      }
      return generateResult(error.status, error.statusText, data);
    } else if (error.message === 'Failed to fetch') {
      let e = generateResult(9998, error.message, { errorCode: 9998 });
      SystemError.processRequestError(e);
    } else {
      //소스 에러
      return generateResult(9999, 'front end error', {
        message: error,
        url,
        data,
        options,
        method,
      });
    }
  }
};
