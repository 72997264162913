import * as Sentry from '@sentry/nextjs';

const handler = async (req, res) => {
  Sentry.init({
    dsn: 'http://9fe291f6b61649cf9816cf6855cd166a@dodlab.xyz:9000/22',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};

export default handler;
