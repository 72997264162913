import React from 'react';
import Router from 'next/router';
import BASIC_CONSTANT from '../../constants/basic.constants.js/basic.constants.js';
import URL_CONSTANT from 'constants/url.constants.js';

const ErrorHandle = props => {
  const { statusCode, code, description } = props;
  if (code == 401) {
    localStorage.removeItem(BASIC_CONSTANT.USER_TOKEN_KEY);
    Router.push(URL_CONSTANT.login);
  } else if (code == 403) {
    Router.push(URL_CONSTANT['error/forbidden']);
  } else if (code == 404) {
    Router.push(URL_CONSTANT['error/notFound']);
  } else if (code == 400) {
    if (statusCode == 103001) {
      alert('존재하지 않는 유저입니다.');
    } else if (statusCode == 102011) {
      localStorage.removeItem(BASIC_CONSTANT.USER_TOKEN_KEY);
      Router.push(URL_CONSTANT.login);
    } else if (statusCode == 101031) {
      alert('존재하지 않는 채널 또는 유저 입니다.');
    } else if (statusCode == 101026) {
      alert('이미지 최대 업로드 용량을 초과 하였습니다. 5MB 미만으로 업로드 해주세요.');
    } else if (
      statusCode == 101022 ||
      statusCode == 102003 ||
      statusCode == 1020012 ||
      statusCode == 105001 ||
      statusCode == 105003
    ) {
      //memberManage 관련
      alert(description);
    }
  } else if (code == 500) {
    if (statusCode == 101503) {
      alert('이미지 업로드 중에 문제가 발생 했습니다. 다시 시도해 주세요.');
    }
  } else if (code == 502 || code == 503) {
    Router.push(URL_CONSTANT['error/serverCheck']);
  }
};

export default ErrorHandle;
