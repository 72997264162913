/**
 * @author Lee Ji Hoon <jihoonlee@dodlab.kr>
 * @module Saga/UserSaga

 */
import { call, takeLatest, put } from 'redux-saga/effects';
import { requestFetch, REQUEST_TYPE } from '../../utils/requestFetch';
import BASIC_CONSTANT from '../../constants/basic.constants.js/basic.constants.js';
import URL_CONSTANT from '../../constants/url.constants.js';
import Router from 'next/router';
import SystemError from '../../utils/exception/SystemError';

const ls = require('localStorage');

export const USER_LOGIN_START = 'USER_LOGIN_START';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_CHANNEL_USER_PROFILE = 'GET_CHANNEL_USER_PROFILE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_CHANNEL_USER = 'UPDATE_CHANNEL_USER';
export const LOGOUT = 'LOGOUT';

/**
 * @param {object} token
 */
function* getUserProfile({ token, isRedirect }) {
  try {
    const result = yield call(requestFetch, `${BASIC_CONSTANT.BASE_URL}user/me`, REQUEST_TYPE.GET, {}, { token });

    if (result.code === 200) {
      localStorage.setItem(BASIC_CONSTANT.USER_TOKEN_KEY, token);
      result.data.token = token;
      yield put({ type: UPDATE_USER, user: result.data });
      if (isRedirect) Router.push('/');
    } else if (result.code === 401) {
      localStorage.removeItem(BASIC_CONSTANT.USER_TOKEN_KEY);
      yield put({ type: UPDATE_USER, user: null });
      Router.push(URL_CONSTANT.login.url);
    }
  } catch (e) {
    //예외처리
    SystemError.processError(e);
  }
}

function* getChannelUserProfile({ token, channelId }) {
  try {
    const result = yield call(
      requestFetch,
      `${BASIC_CONSTANT.BASE_URL}channels/${channelId}/me`,
      REQUEST_TYPE.GET,
      {},
      { token }
    );

    if (result.code === 200) {
      yield put({ type: UPDATE_CHANNEL_USER, channelUser: result.data });
    } else if (result.code === 400) {
      Router.push(URL_CONSTANT['error/forbidden'].url);
    }
  } catch (e) {
    //예외처리
    SystemError.processError(e);
  }
}

function* logout() {
  yield put({ type: UPDATE_USER, user: null });
}

function* UserSaga() {
  yield takeLatest(GET_USER_PROFILE, getUserProfile);
  yield takeLatest(LOGOUT, logout);
  yield takeLatest(GET_CHANNEL_USER_PROFILE, getChannelUserProfile);
}

export default UserSaga;
