export const ON_NOTIFICATION = 'ON_NOTIFICATION';

export const initialState = {
  notification: null,
};

const NotificationRedux = (state = initialState, action) => {
  // 리듀서
  switch (action.type) {
    case ON_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };

    default:
      return state;
  }
};

export default NotificationRedux;
