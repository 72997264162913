export const REQUEST_RESULT = (error, code, data, jsonData = null) => {
  return {
    error,
    code,
    data,
    jsonData,
  };
};

export const REQUEST_CODE = {
  '404': 404,
  '500': 500,
  FEE: 'front-end error',
};
